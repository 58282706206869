<template>
  <el-container :key="reRender" class="wrapper">
    <el-container class="is-vertical">
      <component :is="headerComponent"></component>
      <el-main id="elMain">
        <transition>
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      reRender: 0
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    headerComponent() {
      return () => this.$platform.componentLoader('shared', 'LoginHeader').component;
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val;
        this.reRender++;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}
</style>
